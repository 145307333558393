/* OrderStatus.css */

/* General Styling */
.order-status-page {
    color: #e0e0e0;
    padding: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .page-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #ffcc99; /* Warm sand color */
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    gap: 0.5rem;
  }
  
  .toggle-button {
    background-color: transparent;
    border: 2px solid #ffcc99;
    color: #ffcc99;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .toggle-button:hover,
  .toggle-button.active {
    background-color: #ffcc99;
    color: #2e2b32;
  }
  
  .form-container {
    width: 100%;
    max-width: 400px;
    background-color: #2e2b32;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 2rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
    color: #ffcc99;
  }
  
  .form-input {
    width: 100%;
    padding: 0.5rem;
    background-color: #1f1f1f;
    color: #e0e0e0;
    border: 1px solid #ffcc99;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  .form-input:focus {
    border-color: #ffcc99;
    outline: none;
  }
  
  .check-status-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #ffcc99;
    color: #2e2b32;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .check-status-button:hover {
    background-color: #f0d8b5;
  }
  
  .error-message {
    color: #ff6666;
    margin-top: 1rem;
  }
  
  .orders-container {
    width: 100%;
    max-width: 600px;
  }
  
  .order-details {
    background-color: #38323e;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .order-title {
    font-size: 1.5rem;
    color: #ffcc99;
    margin-bottom: 1rem;
  }
  
  .order-status {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .status-dot.yellow {
    background-color: #ffc107; /* Yellow for processing */
  }
  
  .status-dot.blue {
    background-color: #2196f3; /* Blue for sent */
  }
  
  .status-dot.green {
    background-color: #4caf50; /* Green for delivered */
  }
  
  .products-title {
    font-size: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .product-item {
    padding: 0.5rem 0;
    border-bottom: 1px solid #444;
  }
  
  .total-price {
    font-weight: bold;
    text-align: right;
    margin-top: 1rem;
  }
  
  /* Mobile Styles */
  @media (max-width: 640px) {
    .order-status-page {
      padding: 10px;
    }
  
    .page-title {
      font-size: 1.5rem;
    }
  
    .order-title {
      font-size: 1.25rem;
    }
  
    .products-title {
      font-size: 1.1rem;
    }
  
    .total-price {
      font-size: 1rem;
    }
  }
