/* client/src/components/PaymentForm.css */

.payment-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 24px;
  border: 1px solid #d1d5db; /* border-gray-300 */
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.order-summary {
  text-align: left;
}

.security-message {
  align-items: center;
  font-size: 12px;
  gap: 5px;
}

/* Adjustments for mobile */
@media (max-width: 640px) {
  .payment-form-container {
    padding: 16px;
  }

  .order-summary {
    font-size: 14px;
  }

  .order-row {
    font-size: 12px;
    padding: 0.5rem;
  }

  .security-message img {
    width: 16px;
    height: 16px;
  }

  .order-totals .font-bold {
    font-size: 16px;
  }
}

.order-row {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  border-bottom: 1px solid #e5e7eb;
}

.order-row:nth-child(even) {
  background-color: #f9fafb;
}

.order-totals {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f3f4f6;
  border-top: 2px solid #d1d5db;
}

.order-totals p {
  margin: 0.25rem 0;
}

.logo-container{
  width: 150px;
  height: auto;
}