/* Products.css */

.product-image-container {
    background-color: white; /* White background */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px; /* Optional: to match the card's rounded corners */
  }
  
  .product-image {
    object-fit: contain; /* Ensure the image fits within the container */
    max-width: 100%;
    max-height: 100%;
  }
  
  

  /* Products.css */

/* Products.css */

/* Apply styles only on screens wider than 1024px (desktop) */
@media (min-width: 1024px) {
  .price-button-desktop {
    font-size: clamp(0.8rem, 1.1vw, 1.15rem); /* Increase the font size */
    padding-left: 0.25rem; /* Adjust padding as needed */
    padding-right: 0.25rem;
  }
}

.price-button {
  white-space: nowrap;
}
