/* client/src/components/StickyCylinders.css */

/* Sticky Cylinder Container */
.stickyIsland {
  position: fixed;
  bottom: 24px;
  right: 16px;
  background-color: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 250px; /* Default width for desktop */
  overflow: hidden;
  cursor: pointer;
  z-index: 50;
}

.stickyIsland .cartLabel {
  font-size: 12px;
  margin-bottom: 4px;
  text-align: left;
}

/* Flex Container for Bottles */
.flexContainer {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  overflow-x: auto; /* Enable horizontal scrolling */
  justify-content: flex-end;
  gap: 6px;
}

/* Bottle Wrapper */
.bottleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* Modal Overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

/* Modal Content */
.modalContentFullScreen {
  background-color: #2c2c2c;
  padding: 30px;
  border-radius: 8px;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
}

/* Product Item */
.productItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/* Bottle Image */
.bottleImage {
  width: 75px; /* Default width for desktop */
  height: auto;
  margin-right: 15px;
}

/* Product Details */
.productDetails {
  flex-grow: 1;
  padding-left: 10px;
  text-align: left;
}

/* Quantity Select */
.quantitySelect {
  margin-left: 8px;
  padding: 4px;
  font-size: 14px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid black;
  color: black;
}

/* Remove Button */
.removeButton {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f44336;
  color: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

/* Total Price */
.totalPrice {
  font-weight: bold;
  text-align: right;
  margin-top: 20px;
  font-size: 18px;
  border-top: 1px solid #444;
  padding-top: 10px;
}

/* Action Buttons */
.actionButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

/* Adjustments for Mobile */
@media (max-width: 640px) {
  .stickyIsland {
    width: auto; /* Remove fixed width */
    max-width: 224px; /* Max width to fit up to 5 cylinders */
    padding: 8px; /* Smaller padding */
    right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
  }

  .stickyIsland .cartLabel {
    text-align: left; /* Align 'Cart:' label to the left */
    margin-bottom: 9px; /* Increased margin to add 5px extra space */
  }

  .flexContainer {
    flex-direction: row; /* Cylinders added from left to right */
    gap: 10px; /* Reduced gap between cylinders to zero */
    overflow-x: visible; /* Remove horizontal scrolling */
    align-items: flex-start; /* Align items to the top */
  }

  .bottleWrapper {
    /* Removed width and height to let it wrap the content tightly */
    margin: 0;
    padding: 0;
  }

  /* Adjustments for mobile modal */
  .modalContentFullScreen {
    padding: 20px;
    width: 95%;
  }

  /* Adjustments for product item in modal */
  .productItem {
    flex-direction: column; /* Stack image and details vertically */
    align-items: flex-start;
  }

  .bottleImage {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .actionButtons {
    flex-direction: column;
    align-items: stretch;
  }

  .actionButtons button {
    margin-bottom: 10px;
    width: 100%;
  }

  .removeButton {
    width: 25px; /* Make the remove button smaller */
    height: 25px;
    font-size: 14px; /* Reduce the font size */
  }

  .quantitySelect {
    font-size: 12px; /* Smaller font for the quantity select dropdown */
  }
  .modalContentFullScreen {
    padding: 20px;
    width: 95%;
    max-height: 80vh; /* Reduce max height for mobile */
    margin-top: 10vh; /* Add top margin to avoid navbar overlap */
    margin-bottom: 10vh; /* Add bottom margin to avoid overlap with browser UI */
    overflow-y: auto; /* Enable scrolling inside the modal */
  }

  /* Adjust product item layout */
  .productItem {
    flex-direction: row; /* Display items in one line */
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  .productDetails {
    flex-grow: 1;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
  }

  .productName {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .quantity {
    display: inline-block;
    margin-left: 0px;
  }

  .removeButton {
    align-self: flex-start; /* Align remove button to the top */
    margin-left: 10px;
  }

  /* Prevent background from scrolling when modal is open */
  body.modal-open {
    overflow: hidden;
  }
  
}


.notification-modal {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999; /* Ensure it's above other modals */
  background-color: #28a745; /* Green background */
  color: white; /* White text */
  padding: 15px 30px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
}

.notification-success {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28a745; /* Bright green background */
  color: white; /* White text */
  padding: 20px 40px;
  font-size: 18px; /* Bigger font for visibility */
  border-radius: 5px;
  z-index: 10000; /* Make sure it appears above everything */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}
