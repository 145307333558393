/* Sticky Header Styling */
.stickyHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Add shadow for sticky header */
    transition: top 0.3s; /* Smooth transition for hiding/showing */
  }
  
  /* Navbar Content */
  .nav-content {
    padding: 10px 15px; /* Adjust padding */
  }
  
/* Mobile Dropdown Menu Styling */
.mobileMenu {
    position: absolute;
    top: 60px; /* Adjust top position below header */
    right: 16px;
    background-color: #3a3a3a; /* Distinct background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); /* Add shadow for depth */
    padding: 20px; /* Spacing inside the menu */
    z-index: 999;
    display: none; /* Hide menu by default */
    opacity: 0; /* Set initial opacity */
    transition: opacity 0.3s ease-in-out; /* Smooth transition for showing */
}

.mobileMenu.open {
    display: block; /* Show menu when open */
    opacity: 1; /* Set opacity to visible */
}

.mobileMenu a {
    display: block;
    padding: 10px 0; /* Increase spacing between menu items */
    text-decoration: none;
    color: #fff; /* White text for contrast */
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

.mobileMenu a:hover {
    background-color: #555; /* Slight hover effect */
    color: #ffcc99; /* Match the warm sand color for highlighting */
}

  /* Sticky Header for Mobile */
  @media (max-width: 640px) {
    .stickyHeader {
      padding: 10px 15px;
    }
  }
  