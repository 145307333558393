.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes bubbleMove {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-40px) translateX(10px); /* Move up and slightly right */
  }
  100% {
    transform: translateY(-80px) translateX(-10px); /* Move up and left */
    opacity: 0; /* Fade out as it goes up */
  }
}

.bubble {
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7); /* Transparent bubble */
  border-radius: 50%;
  animation: bubbleMove 6s infinite ease-in-out; /* Infinite upward movement */
}


/* Prevent text selection */
* {
  user-select: none;
}

/* Prevent image dragging */
img {
  pointer-events: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.text-blue-500 {
  color: #4299e1;
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}
.grecaptcha-badge {
  width: 40px;  /* Fixed size */
  height: 40px; /* Fixed size */
  transform-origin: 0 0;  /* Ensure the transformation keeps it in the bottom-left */
  left: 0 !important;     /* Keep it on the left */
  right: auto !important; /* Override the default right alignment */
  transition: all 0.3s ease; /* Smooth transition on hover */
  overflow: hidden; /* Hide overflowing text */
}

/* On hover, expand to show full badge */
.grecaptcha-badge:hover {
  width: auto;
  height: auto;
  transform: scale(1);  /* Show full size when hovered */
}

/* Further scale down on mobile */
@media (max-width: 768px) {
  .grecaptcha-badge {
    transform: scale(0.6);  /* Smaller size for mobile */
    width: 30px;  /* Fixed smaller size */
    height: 30px; /* Fixed smaller size */
  }
}
