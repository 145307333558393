/* Custom Modal Overlay */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Custom Modal Container */
.custom-modal-container {
  background: #fff;
  padding: 2rem;
  width: 90%;
  max-width: 650px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Custom Modal Title */
.custom-modal-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
}

/* Custom Modal Content */
.custom-modal-content {
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 1rem;
  line-height: 1.6;
  color: #444;
}

/* Modal Close Button */
.custom-modal-close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.custom-modal-close-btn:hover {
  color: red;
}

/* Responsive Design */
@media (min-width: 768px) {
  .custom-modal-container {
      max-width: 50%;
  }
}

/* Custom Modal Content Container */
.custom-modal-content-container {
  padding: 1rem;
  text-align: left; /* Add this to align content left */
}


.custom-modal-header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.custom-modal-subheader {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.custom-modal-paragraph {
  margin-bottom: 1rem;
  line-height: 1.8;
}

.custom-modal-effective-date {
  margin-bottom: 1rem;
  font-style: italic;
}
