/* client/src/components/BottleWithPerfume.css */

/* Container for Perfume Bottle */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px; /* Default width for desktop */
  height: 75px; /* Default height for desktop */
  margin: 5px;
  position: relative;
}

@media (max-width: 640px) {
  .container {
    width: 20px; /* Adjusted width for mobile */
    height: 50px; /* Adjusted height for mobile */
    margin: 0; /* Remove margin on mobile to eliminate gaps */
  }
}

/* Bottle Cap */
.bottleCap {
  width: 25px; /* Default width for desktop */
  height: 7px; /* Default height for desktop */
  background-color: #6d4c41;
  border-radius: 50% 50% 0 0;
  margin-bottom: 3px;
}

@media (max-width: 640px) {
  .bottleCap {
    width: 18px; /* Adjusted width for mobile */
    height: 5px; /* Adjusted height for mobile */
  }
}

/* Bottle Body */
.bottleBody {
  width: 25px; /* Default width for desktop */
  height: 60px; /* Default height for desktop */
  background-color: #e0e0e0;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 640px) {
  .bottleBody {
    width: 18px; /* Adjusted width for mobile */
    height: 45px; /* Adjusted height for mobile */
  }
}

/* Perfume Fill */
.perfume-fill {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  transition: height 1.5s ease;
  border-radius: 0 0 10px 10px;
}

/* Bubbles Container */
.bubblesContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
